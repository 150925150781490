import { type ClassValue, clsx } from 'clsx'
import { twMerge } from 'tailwind-merge'
import { userMetadatasRow } from './dbTypes'
import { Availability } from './genericTypes'

export const BASE_URL = process.env.NEXT_PUBLIC_LOCAL_DEV
  ? 'http://localhost:3000'
  : 'https://www.usesmithers.com'
export const AUTH_CALLBACK_URL = `${BASE_URL}/auth/callback`
export const ADMIN_CALLBACK_URL = `${BASE_URL}/auth/admincallback`
export const EMAIL_ALIAS = process.env.SMITHERS_EMAIL_ACCOUNT_ID || ''
export const EMAIL_NAME =
  EMAIL_ALIAS == 'smithersdev@usesmithers.com'
    ? 'Smithers AI - Dev Mode'
    : 'Smithers AI'

// we use these strings to check if theres some back and forth communication between
// smithers and the client that we dont want to send to the user.
export const PLEASE_CONFIRM = 'Please confirm'
export const BEFORE_I_CONFIRM = 'Before I check'

export const scopes =
  'https://www.googleapis.com/auth/calendar.readonly,https://www.googleapis.com/auth/calendar.events'

// any time u change the footer, dont delete the original regex - just add a new one.
export const EMAIL_FOOTER_REGEXES = [
  /Smithers\s*AI\s*https:\/\/usesmithers\.com\s*\|\s*Hyper-focused\s*scheduling\s*assistant/,
  /Try\s*out\s*the\s*hyper-focused\s*scheduling\s*assistant,\s*Smithers\s*AI\s*https:\/\/usesmithers\.com\./,
  /Smithers\s*AI\s*https:\/\/usesmithers\.com\s*\|\s*Email\s*smithers@usesmithers\.com\s*to\s*get\s*your\s*own\s*Executive\s*assistant/,
]
export const EMAIL_FOOTER = `<br><br><table width="640" cellpadding="0" cellspacing="0" border="0" style="border-collapse:collapse;width:640px;max-width:100%;border-top:1px solid rgb(199,199,199)">
  <tbody><tr>
    <th style="text-align:left;max-width:640px;margin:0px auto;padding:0px;display:block;border-collapse:collapse">
      <font face="arial, sans-serif" color="#cccccc">
        <span style="font-weight:400">
        <a style="display:inline-block;" href="https://usesmithers.com" target="_blank" data-saferedirecturl="https://www.google.com/url?q=https://usesmithers.com&amp;source=gmail&amp;ust=1718317121908000&amp;usg=AOvVaw3Rk02q-Lz3XjAbcP7RyvIi">
        Smithers AI
      </a>&nbsp;| Email smithers@usesmithers.com to get your own Executive Assistant.
        </span>
        <span style="font-weight:400">.</span>
      </font><br>
    </th></tr>
  </tbody>
</table>`

export const ToOrganizerOnlyText = '{Premium-User}'
export const OrganizerOnlyTag = `<span style="display: none">${ToOrganizerOnlyText}</span>`

export enum LATEST_KNOWN_MSGS {
  NONE = 'NONE',
  SENT_CONFUSED_TO_ORGANIZER = 'SENT_CONFUSED_TO_ORGANIZER',
  SENT_POTENTIAL_TIMES_CONFIRM_TO_ORGANIZER = 'SENT_POTENTIAL_TIMES_CONFIRM_TO_ORGANIZER',
  SENT_POTENTIAL_TIMES_TO_PARTICIPANTS = 'SENT_POTENTIAL_TIMES_TO_PARTICIPANTS',
  SENT_BOOKING_CONFIRM_TO_ORGANIZER = 'SENT_BOOKING_CONFIRM_TO_ORGANIZER',
  SENT_BOOK_TO_ORGANIZER = 'SENT_BOOK_TO_ORGANIZER',
  SENT_BOOK_TO_PARTICIPANTS = 'SENT_BOOK_TO_PARTICIPANTS',
  SENT_RESCHEDULE_TO_PARTICIPANTS = 'SENT_RESCHEDULE_TO_PARTICIPANTS',
  SENT_BOOKING_COMPLETE_TO_PARTICIPANTS = 'SENT_BOOKING_COMPLETE_TO_PARTICIPANTS',
  SENT_CONFUSED_TO_PARTICIPANTS = 'SENT_CONFUSED_TO_PARTICIPANTS',

  SENT_MODIFY_MEETING_TO_ORGANIZER = 'SENT_MODIFY_MEETING_TO_ORGANIZER',
  SENT_MODIFY_MEETING_TO_PARTICIPANTS = 'SENT_MODIFY_MEETING_TO_PARTICIPANTS',
  SENT_CANCEL_MEETING_TO_ORGANIZER = 'SENT_CANCEL_MEETING_TO_ORGANIZER',
  SENT_CANCEL_MEETING_TO_PARTICIPANT = 'SENT_CANCEL_MEETING_TO_PARTICIPANT',
  SENT_MEETING_DETAILS_TO_ORGANIZER = 'SENT_MEETING_DETAILS_TO_ORGANIZER',
  SENT_MEETING_DETAILS_TO_PARTICIPANT = 'SENT_MEETING_DETAILS_TO_PARTICIPANT',

  SENT_BROAD_MODIFY_CONFIRM_TO_ORGANIZER = 'SENT_BROAD_MODIFY_CONFIRM_TO_ORGANIZER',
  SENT_BROAD_MODIFY_CONFIRM_TO_PARTICIPANTS = 'SENT_BROAD_MODIFY_CONFIRM_TO_PARTICIPANTS',

  SENT_CONFIRM_INFO_TO_PARTICIPANTS = 'SENT_CONFIRM_INFO_TO_PARTICIPANTS',

  SENT_NO_RESPONSE_TO_ORGANIZER = 'SENT_NO_RESPONSE_TO_ORGANIZER',
  SENT_NO_RESPONSE_TO_PARTICIPANT = 'SENT_NO_RESPONSE_TO_PARTICIPANT',
}
/* eslint-enable */

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function joinArrayWithAnd(arr: string[]): string {
  if (arr.length === 1) return arr[0]
  if (arr.length === 2) return `${arr[0]} and ${arr[1]}`
  return `${arr.slice(0, -1).join(', ')} and ${arr[arr.length - 1]}`
}

export const extractAvailabilityFromUserMetadatas = (
  userMetadata: userMetadatasRow,
) => {
  const av = JSON.parse(userMetadata.userAvailabilityJson)
  for (const key in av) {
    if (!Array.isArray(av[key])) {
      av[key] = [av[key]]
    }
  }
  return av as Availability
}
